// GraphQL
import { useStaticQuery, graphql } from "gatsby";

const getHowToUseData = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useStaticQuery(graphql`
    query HowToUseQuery {
      fertilityImage: contentfulAsset(
        contentful_id: { eq: "1lzXSTHByFTcBoSTcn3iaX" }
      ) {
        file {
          url
        }
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 970
          quality: 90
        )
        description
      }
    }
  `);

  return {
    "essential.fertility": {
      content: [
        {
          id: "product.fertility.how-to-use.steps.first",
          defaultMessage: "Add to your routine 2x per day, AM and PM",
        },
        {
          id: "product.fertility.how-to-use.steps.second",
          defaultMessage: "Start with 8-12 oz of any liquid",
        },
        {
          id: "product.fertility.how-to-use.steps.third",
          defaultMessage: "Add 1 scoop (about 4g) of powder",
        },
        {
          id: "product.fertility.how-to-use.steps.fourth",
          defaultMessage: "Mix like you mean it with a spoon, frother, or shaker",
        },
      ],
      instructions: {
        id: "product.fertility.how-to-use.instructions",
        defaultMessage:
          "Tip: Like lemonade, it tastes best made with cold water & served with ice. Fertility Support can be safely taken with or without food, (as with all Ritual supplements).*",
      },
      disclaimer: {
        id: "product.fertility.how-to-use.disclaimer",
        defaultMessage:
          "Notice a few floating yellow particles? That’s our clinically-studied bioavailable CoQ10. CoQ10 doesn’t dissolve in water, but it is an important supplement for fertility support.*",
      },
      image: data?.fertilityImage,
    },
  };
};

export { getHowToUseData };

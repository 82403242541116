import { useState } from "react";
import styled from "styled-components";

// Components
import Container from "../Container";
import Row from "../Row";
import IngredientOverviewCard from "./IngredientOverviewCard";
import ProteinIngredientStories from "./protein/ProteinIngredientStories";
import SupplementFacts from "./SupplementFacts";
import Text from "../Text";

// Utils
import { Font, Color, rem, responsive, Opacity } from "../../utils/style";
import metrics from "../../utils/metrics";
import QualityOverQuantity from "./protein/QualityOverQuantity";

// Styled Elements
const Wrapper = styled.div.attrs({
  role: "section",
  "aria-labelledby": "protein-ingredients-overview-header",
})`
  margin-bottom: 80px;

  ${responsive.md`
    margin-bottom: 120px;
  `};
`;

const HeaderColumn = styled.div.attrs({
  className: "col-12 col-sm-8 offset-sm-2",
})`
  margin-bottom: 40px;

  ${responsive.md`
    margin-bottom: 80px;
  `};
`;

const Header = styled.h2.attrs({
  id: "protein-ingredients-overview-header",
})`
  ${Font.circular};
  font-size: ${rem(30)};
  line-height: ${rem(36)};
  font-weight: 400;
  letter-spacing: -0.4px;
  text-align: center !important;
  color: ${Color.ritualBlue};
  margin: 0 auto 16px;

  ${responsive.md`
    font-size: ${rem(48)};
    line-height: ${rem(54)};
    letter-spacing: -1px;
    margin: 0 0 24px;
  `}
`;

const Subhead = styled.p`
  ${Font.dutch};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: normal;
  text-align: center;
  letter-spacing: 0;
  color: ${Color.ritualBlue};
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `}
  span {
    font: inherit;
  }
`;

const IngredientsRow = styled.div``;

const SupplementLink = styled.button`
  padding: 0;
  background: none;
  border: none;
  color: ${Color.ritualBlue};
  border-bottom: 1px solid ${Color.ritualBlue};
  cursor: pointer;
  transition: opacity 200ms ease-in-out;
  line-height: ${rem(26)};

  &:hover {
    opacity: ${Opacity.light};
  }
`;

const OtherIngredients = styled.div.attrs({
  className: "col-12",
})`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 80px;

  ${responsive.md`
    margin-bottom: 120px;
  `};

  p {
    text-align: center;
    margin: 0;
    max-width: 900px;
  }
`;

const IngredientsOverviewSection = (props: any) => {
  const {
    product,
    ingredients,
    ingredientsSubheader,
    showQualityOverQuantity = true,
    showOtherIngredients = true,
  } = props;

  const [isSupplementFactsOpen, setSupplementFactsOpen] = useState(false);

  const handlePopup = () => {
    setSupplementFactsOpen(true);
  }

  const closeModal = () => {
    setSupplementFactsOpen(false);
  }

  const clickEvent = (ingredient: any) => {
    metrics.track("Ingredient Card Clicked", {
      name: ingredient.name,
      location: "Ingredient List",
    });
  }

  if (!ingredients || !ingredients.length) {
    return null;
  }

  const visibleCards = 3;

  const qualityOverQuantityIngredients = ingredients.slice(
    ingredients.length - 2,
  );

  const otherIngredients = product.supplementFacts.otherIngredients.replace(
    "**Derived from Non-GMO Sunflower",
    "",
  );

  return (
    <Wrapper>
      <Container>
        <Row>
          <HeaderColumn>
            <Header>
              Traceable Ingredients
              {/* <Text
                id="product.protein.ingredients.header"
                defaultMessage="Traceable Ingredients"
                values
              /> */}
            </Header>
            <Subhead>
              {ingredientsSubheader.childMarkdownRemark.rawMarkdownBody}{" "}
              <SupplementLink onClick={handlePopup}>
                View Supplement Facts
                {/* <Text
                  id="product.ingredients.view-facts"
                  defaultMessage="View Supplement Facts"
                /> */}
              </SupplementLink>
            </Subhead>
          </HeaderColumn>
        </Row>
      </Container>
      <IngredientsRow>
        {ingredients.slice(0, visibleCards).map((ingredient: any, index: number) => {
          return (
            <>
              <IngredientOverviewCard
                index={index}
                product={product}
                ingredient={ingredient}
                handleClick={clickEvent}
              />
              <ProteinIngredientStories ingredient={ingredient} />
            </>
          );
        })}
      </IngredientsRow>
      {showQualityOverQuantity && ( // TODO: Extract this out as an option in Contentful
        <QualityOverQuantity ingredients={qualityOverQuantityIngredients} />
      )}

      {showOtherIngredients && (
        <Container>
          <Row>
            <OtherIngredients>
              {product.supplementFacts && (
                <p data-test-other-ingredients>
                  <Text
                    id="product.protein.ingredients.other-ingredients"
                    defaultMessage={`Ingredients: ${otherIngredients}`}
                    values={{
                      otherIngredients,
                    }}
                  />
                </p>
              )}
            </OtherIngredients>
          </Row>
        </Container>
      )}

      {product.supplementFacts && (
        <SupplementFacts
          supplementFacts={product.supplementFacts}
          isOpen={isSupplementFactsOpen}
          onRequestClose={closeModal}
        />
      )}
    </Wrapper>
  );
}

export default IngredientsOverviewSection;

// export default class ProteinIngredientsOverview extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       factsOpen: false,
//       // isSupplementFactsOpen: false,
//       // cardLimits: {
//       //   small: 4,
//       //   large: 6,
//       // },
//       // expandedView: false,
//       // deviceState: "large",
//     };

//     // this.clickEvent = this.clickEvent.bind(this);
//     // this.closeModal = this.closeModal.bind(this);
//   }

//   // handlePopup() {
//   //   this.setState({
//   //     isSupplementFactsOpen: true,
//   //   });
//   // }

//   // closeModal() {
//   //   this.setState({
//   //     isSupplementFactsOpen: false,
//   //   });
//   // }

//   // clickEvent(ingredient) {
//   //   metrics.track("Ingredient Card Clicked", {
//   //     name: ingredient.name,
//   //     location: "Ingredient List",
//   //   });
//   // }

//   render() {
//     const {
//       product,
//       ingredients,
//       ingredientsSubheader,
//       showQualityOverQuantity = true,
//       showOtherIngredients = true,
//     } = this.props;

//     if (!ingredients || !ingredients.length) {
//       return null;
//     }

//     const { isSupplementFactsOpen } = this.state;
//     const visibleCards = 3;

//     const qualityOverQuantityIngredients = ingredients.slice(
//       ingredients.length - 2,
//     );

//     const otherIngredients = product.supplementFacts.otherIngredients.replace(
//       "**Derived from Non-GMO Sunflower",
//       "",
//     );

//     return (
//       <Wrapper>
//         <Container>
//           <Row>
//             <HeaderColumn>
//               <Header>
//                 <Text
//                   id="product.protein.ingredients.header"
//                   defaultMessage="Traceable Ingredients"
//                 />
//               </Header>
//               <Subhead>
//                 {ingredientsSubheader.childMarkdownRemark.rawMarkdownBody}{" "}
//                 <SupplementLink onClick={this.handlePopup.bind(this)}>
//                   <Text
//                     id="product.ingredients.view-facts"
//                     defaultMessage="View Supplement Facts"
//                   />
//                 </SupplementLink>
//               </Subhead>
//             </HeaderColumn>
//           </Row>
//         </Container>
//         <IngredientsRow>
//           {ingredients.slice(0, visibleCards).map((ingredient, index) => {
//             return (
//               <Fragment key={`protein-ingredient-${ingredient.contentful_id}`}>
//                 <ProteinIngredientCard
//                   index={index}
//                   product={product}
//                   ingredient={ingredient}
//                   handleClick={this.clickEvent}
//                 />
//                 <ProteinIngredientStories ingredient={ingredient} />
//               </Fragment>
//             );
//           })}
//         </IngredientsRow>
//         {showQualityOverQuantity && ( // TODO: Extract this out as an option in Contentful
//           <QualityOverQuantity ingredients={qualityOverQuantityIngredients} />
//         )}

//         {showOtherIngredients && (
//           <Container>
//             <Row>
//               <OtherIngredients>
//                 {product.supplementFacts && (
//                   <p data-test-other-ingredients>
//                     <Text
//                       id="product.protein.ingredients.other-ingredients"
//                       defaultMessage={`Ingredients: ${otherIngredients}`}
//                       values={{
//                         otherIngredients,
//                       }}
//                     />
//                   </p>
//                 )}
//               </OtherIngredients>
//             </Row>
//           </Container>
//         )}

//         {product.supplementFacts && (
//           <SupplementFacts
//             supplementFacts={product.supplementFacts}
//             isOpen={isSupplementFactsOpen}
//             onRequestClose={this.closeModal}
//           />
//         )}
//       </Wrapper>
//     );
//   }
// }

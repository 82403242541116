import { graphql } from "gatsby";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Components
import StickyProductNav from "../components/global/StickyProductNav";
import ProductDetailPage from "../components/product/templates/ProductDetailPage";
import SingleProductDetailPage from "../components/product/templates/SingleProductDetailPage";
import ProductSEO from "../components/seo/Product";
import productSelectors from "../store/product/selectors";

// Utils
import useSeoPricing from "../hooks/seo/useSeoPricing";
import usePromotionDiscount from "../hooks/usePromotionDiscount";
import { default as useVariation } from "../hooks/useVariation";
import { navigate } from "../services/navigation";
import { bestEligiblePromotion } from "../store/promotion/selectors";
import { getCadencePlans } from "../utils/ritualData";
import {
  getPlanIdForProductSku,
  getProductAttributesForSku,
} from "../utils/planToProduct";
import { getSeoCategoryFromProduct } from "../utils/product";
import { trackProductViewed } from "../utils/tracking/product";

const Seo = (props) => {
  const {
    data,
    pageContext: { productId },
    reviewRatingsMap,
    reviewCountsMap,
    ritualProduct,
    ctfProduct: {
      slug,
      summary,
      pageTitle,
      pageDescription,
      socialImage,
      stockStatus,
      contentfulId,
      gtin,
    },
    productAttributes: { name, sku, price, currency, variant },
  } = props;

  const promotion = useSelector(bestEligiblePromotion);
  const promotionDiscount = usePromotionDiscount(promotion, variant);
  const { amount, discountAmount, priceValidUntil } = useSeoPricing(
    price,
    promotionDiscount,
  );

  const seoProps = {
    pagePath: `products/${slug}`,
    title: pageTitle,
    description: pageDescription,
    image: {
      url: socialImage.file.url,
      width: socialImage.file.details.image.width,
      height: socialImage.file.details.image.height,
    },
    productAttributes: {
      contentfulId,
      ratingValue: reviewRatingsMap?.[productId],
      reviewCount: reviewCountsMap?.[productId],
      topReview: data.mostHelpful,
      name,
      description: summary,
      productId,
      sku,
      gtin,
      brand: {
        "@type": "Brand",
        name: "Ritual",
      },
      availability: stockStatus,
      condition: "new",
      category: getSeoCategoryFromProduct(ritualProduct.subCategory),
      amount,
      discountAmount,
      priceValidUntil,
      currency,
      stockStatus,
    },
  };

  return <ProductSEO {...seoProps} />;
};

const navigateToProduct = (product) => {
  navigate(`/products/${product.slug}`);
};

const useTrackProductViewed = (sku) => {
  useEffect(() => {
    trackProductViewed(getPlanIdForProductSku(sku));
  }, [sku]);
};

const ProductPageTemplate = (props) => {
  const ctfProduct = props.data.contentfulProduct;
  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const ritualProduct = useSelector((state) => {
    return productSelectors.productForSku(state, ctfProduct);
  });

  const isSingleSkuPdpUpdates = useVariation("single-sku-pdp-updates", false);

  // Set selectedPlanId with proper plan for Single Sku PDP template
  if (isSingleSkuPdpUpdates && selectedPlanId === null) {
    const cadencePlans = getCadencePlans(
      ctfProduct.ritualProduct.ritualPlans,
      props.pageContext.currency,
      true,
    );
    const defaultPlan = cadencePlans.find((plan) => plan.interval_count === 30);

    setSelectedPlanId(defaultPlan.plan_id);
  }

  const productAttributes = getProductAttributesForSku(ctfProduct.sku) || {};
  const { showReviews } = ctfProduct;

  const reviewCountsMap = useSelector(productSelectors.reviewCountsMap);
  const reviewRatingsMap = useSelector(productSelectors.reviewRatingsMap);

  const [quantity, setQuantity] = useState(1);

  useTrackProductViewed(ctfProduct.sku);

  return (
    <>
      <Seo
        {...props}
        reviewRatingsMap={showReviews ? reviewRatingsMap : null}
        reviewCountsMap={showReviews ? reviewCountsMap : null}
        ritualProduct={ritualProduct}
        ctfProduct={ctfProduct}
        productAttributes={productAttributes}
      />

      {isSingleSkuPdpUpdates ? (
        <>
          <StickyProductNav
            sku={ctfProduct.sku}
            ctfProduct={ctfProduct}
            ctaLocation={"Sticky Nav"}
            showTopBar={false}
            quantity={quantity}
            selectedPlanId={selectedPlanId}
            cadencePlanCta={true}
          />
          <SingleProductDetailPage
            {...props}
            productAttributes={productAttributes}
            product={ritualProduct}
            handleSelectedProduct={navigateToProduct}
            reviewRatingsMap={reviewRatingsMap}
            reviewCountsMap={reviewCountsMap}
            selectedPlanId={selectedPlanId}
            setSelectedPlanId={setSelectedPlanId}
          />
        </>
      ) : (
        <>
          <StickyProductNav
            sku={ctfProduct.sku}
            ctfProduct={ctfProduct}
            ctaLocation={"Sticky Nav"}
            showTopBar={false}
            quantity={quantity}
            selectedPlanId={selectedPlanId}
          />
          <ProductDetailPage
            {...props}
            productAttributes={productAttributes}
            product={ritualProduct}
            quantity={quantity}
            updateQuantity={setQuantity}
            selectedProduct={ctfProduct}
            handleSelectedProduct={navigateToProduct}
            sendPlanId={setSelectedPlanId}
            reviewRatingsMap={reviewRatingsMap}
            reviewCountsMap={reviewCountsMap}
          />
        </>
      )}
    </>
  );
};

export default ProductPageTemplate;

export const query = graphql`
  query ProductQuery($locale: String!, $slug: String!, $productId: String!) {
    mostHelpful: highlightReview(
      reviewType: { eq: "Most Helpful" }
      destination: { eq: "pdp" }
      product_slug: { eq: $slug }
    ) {
      id
      score
      content
      title
      user {
        display_name
      }
    }
    mostCritical: highlightReview(
      reviewType: { eq: "Most Critical" }
      destination: { eq: "pdp" }
      product_slug: { eq: $slug }
    ) {
      id
      score
      content
      title
      user {
        display_name
      }
    }
    allProductReview(
      sort: { fields: [created_at], order: DESC }
      filter: { product_id: { eq: $productId } }
    ) {
      edges {
        node {
          id
          product_id
          score
          votes_up
          votes_down
          content
          title
          sentiment
          created_at
          verified_buyer
          sticker
          age_range
          subscription_age
          would_recommend
          received_product
          comment {
            id
            content
            created_at
          }
          user {
            user_id
            display_name
          }
        }
      }
    }
    contentfulProduct(node_locale: { eq: $locale }, slug: { eq: $slug }) {
      name {
        name
        childMarkdownRemark {
          html
        }
      }
      category
      showReviews
      stockStatus
      pillSectionTitle {
        childMarkdownRemark {
          html
        }
      }
      pillSectionImage {
        description
        gatsbyImageData(
          layout: CONSTRAINED
          width: 470
          height: 470
          quality: 90
        )
      }
      pillSectionAccordion {
        title
        content
      }
      summary
      headerDescription
      formulaHighlights {
        label
        value
      }
      slug
      gtin
      articles {
        ...featuredArticlesFragment
        heroImage {
          file {
            url
          }
          gatsbyImageData(
            layout: CONSTRAINED
            height: 400
            quality: 90
            cropFocus: LEFT
            resizingBehavior: FILL
          )
          desktop: gatsbyImageData(
            layout: CONSTRAINED
            width: 470
            height: 341
            quality: 100
            cropFocus: LEFT
            resizingBehavior: FILL
          )
          mobile: gatsbyImageData(
            layout: CONSTRAINED
            width: 335
            height: 341
            quality: 100
            cropFocus: LEFT
            resizingBehavior: FILL
          )
        }
        primaryContent {
          __typename
          ... on ContentfulContentGeneric {
            contentBody: body {
              childMarkdownRemark {
                timeToRead
              }
            }
          }
        }
      }
      ingredients {
        __typename
        name
        slug
        description
        officialName
        source
        contentful_id
        supplier
        function {
          function
        }
        benefit
        nutritionFact
        productDosages {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        manufacturingLocation
        cardImage {
          title
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 500
            quality: 80
          )
        }
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 668
            quality: 80
          )
        }
        modalDescription
        modalDesktopImage {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 770
            quality: 80
          )
        }
        ingredientStoryContent {
          description
          title
          id
        }
        ingredientStoryImages {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 520
            quality: 80
          )
          description
          title
        }
        modalMobileImage {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 520
            quality: 80
          )
        }
        icons {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      valueProps {
        name
        disclaimer
        icon {
          file {
            url
            fileName
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
      howToUse {
        instructions {
          raw
        }
        tooltip {
          content
          type
        }
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 391
            quality: 90
          )
          description
        }
      }
      timelineHeading {
        childMarkdownRemark {
          html
        }
      }
      timelineSubhead
      timelinePhoto {
        title
        desktop: gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 470
          quality: 90
        )
        mobile: gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 280
          quality: 90
        )
        description
      }
      timelineVideo {
        ...videoContent
      }
      timelineData {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      designTour {
        title
        subheading
        config {
          duration
          frameCount
          fileNamePrefix
        }
        blocks {
          title
          text
        }
      }
      faq {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      ingredientsSubheader {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      somethingMissingTitle
      experts {
        name
        title
        titleSubhead
        expertise
        image {
          description
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 300
            quality: 90
          )
        }
      }
      doctorGuide {
        file {
          url
        }
      }
      heroBackgroundColor
      heroFeaturedImages {
        title
        gatsbyImageData(
          layout: CONSTRAINED
          width: 555
          quality: 90
          formats: [AUTO]
        )
        description
      }
      heroZoomImages: heroFeaturedImages {
        title
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1920
          quality: 90
          formats: [AUTO]
        )
        description
      }
      pageTitle
      pageDescription
      socialImage {
        file {
          details {
            image {
              width
              height
            }
          }
          url
        }
      }
      sku
      scentSelector
      contentfulId: contentful_id
      productStudyModal {
        linkText
        modalTitle {
          raw
        }
        headerImage {
          description
          gatsbyImageData
        }
        studyDescriptionImage {
          description
          gatsbyImageData(placeholder: NONE)
        }
        mobileStudyDescriptionImage {
          description
          gatsbyImageData(placeholder: NONE)
        }
        content {
          header
          subheader
          studyDescription
          studyResults {
            title
            results
          }
          secondaryStudyResults {
            title
            results
          }
          studySummary {
            title
            content
          }
          disclaimer
        }
      }
      supplementFacts {
        certifications
        servingSize
        servingSizeWeight
        servingsPerContainer
        additionalInformation {
          label
          value
          description
        }
        labels {
          dv
          dv2
        }
        nutrients {
          label
          dosage
          dv
          dosage2
          dv2
        }
        ingredients {
          label
          dosage
          dv
          dosage2
          dv2
        }
        notations {
          label
        }
        otherIngredients
        allergens {
          label
          value
          superset
        }
        notationReferences
        attributions {
          label
          superset
        }
        simpleIngredients {
          name
          dosage
        }
      }
      footnotes {
        childMarkdownRemark {
          html
        }
      }
      ritualProduct {
        product_id
        sku
        in_stock
        sub_category
        url_slug
        ritualPlans {
          plan_id
          amount
          billing_type
          currency
          interval_count
          in_stock
          variant_contents
          variant_description
          base_price
          monthly_amount
          monthly_amount_savings
          monthly_percent_savings
        }
      }
    }
    contentfulClinical(
      node_locale: { eq: $locale }
      product: { slug: { eq: $slug } }
    ) {
      title {
        childMarkdownRemark {
          html
        }
      }
      firstResult {
        childMarkdownRemark {
          html
        }
      }
      secondResult {
        childMarkdownRemark {
          html
        }
      }
      summaryTitle {
        childMarkdownRemark {
          html
        }
      }
      summary {
        childMarkdownRemark {
          html
        }
      }
      about {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";

// Utils
import { buildUrl } from "../../utils/imgix";
import {
  Color,
  Font,
  Opacity,
  atLeast,
  atMost,
  rem,
  responsive,
  rgba,
} from "../../utils/style";

// Animation
import ScrollMagic from "scrollmagic";

// Components
import Container from "../Container";

// Consts
const DESKTOP_NAV_HEIGHT = 139;
const MOBILE_NAV_HEIGHT = 101;
const MOBILE_NAV_OFFSET = 51;

// Styled Elements
const PinnedSection = styled.div.attrs({
  id: "scroll-animation",
})`
  position: relative;
  width: 100%;
  height: 100vh;
  margin-bottom: 80px;

  max-height: calc(100vh - ${MOBILE_NAV_HEIGHT}px);

  ${responsive.md`
    max-height: calc(100vh - ${DESKTOP_NAV_HEIGHT}px);
    margin-bottom: 120px;
  `};

  ${responsive.lg`
    height: 1080px;
  `};

  i {
    ${Font.dutch};
  }
`;

const Wrapper = styled.div`
  position: relative;
  background-color: rgb(243, 242, 246);
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  &::before {
    z-index: 10;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 25vh;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  &::after {
    z-index: 10;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 25vh;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  &::after,
  &::before {
    z-index: 11;
  }

  ${responsive.md`
    &::before,
    &::after {
      height: 25%;
    }
  `};
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
  z-index: 10 !important;
`;

const Title = styled.h2`
  font-size: ${rem(40)};
  font-weight: 450;
  line-height: ${rem(48)};
  text-align: center !important;

  ${responsive.md`
    font-size: ${rem(48)};
    line-height: ${rem(54)};
    text-align: left !important;
  `};
`;

const Content = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  ${responsive.md`
    margin-top: calc(45vh - 50%);
  `};

  ${responsive.lg`
    margin-top: calc(50vh - 50%);
  `};

  li {
    margin-bottom: 65vh;

    ${responsive.md`
      margin-bottom: 104px;
    `};

    ${responsive.lg`
      margin-bottom: 200px;
      max-width: 370px;

      &:first-child {
        margin-bottom: 160px;
        max-width: 100%;
      }
    `};

    &:last-child {
      margin-bottom: 0;
    }

    span {
      font-weight: 500;
      letter-spacing: 0.08em;
      color: ${rgba(Color.ritualBlue, Opacity.light)};
      text-transform: uppercase;
      font-size: ${rem(12)};
      line-height: ${rem(20)};
      margin-bottom: 16px;
      display: block;

      ${responsive.md`
        font-size: ${rem(16)};
        line-height: ${rem(24)};
      `};
    }

    p {
      font-size: ${rem(20)};
      font-weight: 450;

      line-height: ${rem(30)};

      ${responsive.md`
        font-size: ${rem(24)};
        line-height: ${rem(36)};
      `};
    }
  }
`;

const Canvas = styled.canvas`
  width: 85vh;
  height: 85vh;
  max-width: 770px;
  max-height: 770px;
  position: relative;
  z-index: 1 !important;

  ${responsive.md`
    max-width: 1080px;
    max-height: 1080px;
    transform: translateX(25%);
  `};
`;

/**
 *
 * @param {ScrollMagic Controller} controller Controller used for the ScrollMagic Scene
 * @param {Object} data Content object, including config for animation
 */
const ScrollAnimation = ({ controller, data }) => {
  // Scene
  const [scene, setScene] = useState(null); // ScrollMagic scene
  const [progress, setProgress] = useState(0); // Progress of scene, value from 0 to 1
  const [images, setImages] = useState([]);
  const [frame, setFrame] = useState(1);
  const [animating, setAnimating] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  if (!data) return null;

  const { config, title, subheading, blocks } = data;
  const { fileNamePrefix, duration, frameCount } = config;

  if (duration === 0) return null;

  // Refs
  const canvasRef = useRef(null);

  // Handles updating video and content position based on scroll progress
  const handleOnProgress = (e) => {
    const newProgress = e.progress;

    // get current frame based on progress * frameCount
    const currentFrame = Math.floor(newProgress * frameCount);

    // Protection so we don't show a frame that doesn't exist
    if (currentFrame < 1) {
      setFrame(1);
    } else if (currentFrame > frameCount) {
      setFrame(frameCount);
    } else {
      setFrame(currentFrame);
    }

    setProgress(newProgress);
  };

  // Draw current frame to canvas
  const drawCanvas = () => {
    if (!loaded) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Clear previous image
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const image = images[frame - 1];
    // Image should be preloaded in first useEffect
    // If image is not loaded, it will break
    ctx.drawImage(image, 0, 0);
  };

  const preloadImages = () => {
    // Create array of images
    const imageArray = [];
    for (let i = 1; i <= frameCount; i++) {
      // left pad to 3 digits
      const paddedNumber = i.toString().padStart(3, "0");
      const src = `${fileNamePrefix}${paddedNumber}.jpg`;
      imageArray.push(src);
    }

    // Preload array of images and wait until they're all loaded
    Promise.all(
      imageArray.map(
        (img) =>
          new Promise((resolve) => {
            // Get image src using Imgix
            const imageSrc = buildUrl(img);

            // Set max tries if image fails to load
            const maxTries = 5;
            let tries = 0;

            // Create image
            const image = new Image();
            // Set onerror and onload before setting src
            image.onerror = (e) => {
              tries++;
              if (tries >= maxTries) return;
              setTimeout(() => {
                image.src = imageSrc;
              }, 0);
            };
            image.onload = () => resolve(image);
            // Setting src will trigger our onload or onerror events
            image.src = imageSrc;
          }),
      ),
    ).then((array) => {
      setLoaded(true);
      setImages(array);
    });
  };

  const initializeScene = () => {
    if (!loaded) return;

    if (controller) {
      // If scene already exists, destroy and recreate
      if (scene) {
        scene.destroy();
      }

      // Offset determines where to position the scene in relation to the viewport
      // It will move offset depending on screen size and which navs appear
      let offset = isDesktop ? DESKTOP_NAV_HEIGHT * -1 : MOBILE_NAV_HEIGHT * -1;
      if (atMost.mobile()) {
        offset = MOBILE_NAV_OFFSET * -1;
      }

      const newScene = new ScrollMagic.Scene({
        triggerElement: "#scroll-animation",
        triggerHook: 0,
        duration,
        offset: offset,
      })
        .setPin("#scroll-animation")
        .addTo(controller);
      setScene(newScene);

      // Returns a value between 0 and 1, which indicates the progress of the scene
      newScene.on("progress", handleOnProgress);

      newScene.on("enter", () => {
        setAnimating(true);
      });

      newScene.on("leave", () => {
        setAnimating(false);
      });
    }
  };

  // Preload images to ensure smooth animation experience
  useEffect(() => {
    const isDesktop = atLeast.md();
    setIsDesktop(isDesktop);

    const handleScroll = () => {
      // 500 just to be safe, should mean a user scrolls past Hero section before images load
      if (window.scrollY > 500) {
        preloadImages();
        window.removeEventListener("scroll", handleScroll);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Setup scene when controller is available
  useLayoutEffect(() => {
    initializeScene();

    // Cleanup
    return () => {
      if (scene) {
        scene.destroy();
      }
    };
  }, [controller, loaded]);

  // useEffect that listenes to resize events and updates isDesktop
  useEffect(() => {
    const handleResize = () => {
      const isDesktop = atLeast.md();
      setIsDesktop(isDesktop);
      initializeScene();
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Update canvas when frame changes
  useEffect(() => {
    if (!loaded) return;

    if (canvasRef && canvasRef.current && images.length > 0) {
      drawCanvas();
    }
  }, [frame, images, canvasRef, loaded]);

  let posterImage;
  // Set poster image based on if animation or not
  if (animating && loaded) {
    posterImage = undefined;
  } else {
    if (!loaded || progress === 0) {
      posterImage = buildUrl(`${fileNamePrefix}001.jpg`);
    } else if (progress === 1) {
      posterImage = buildUrl(`${fileNamePrefix}${frameCount}.jpg`);
    }
  }

  let translation = `translateY(-${Math.min(progress * 100, 98)}%)`;
  if (isDesktop) {
    translation = `translateY(-${Math.min(progress * 75, 95)}%)`;
  }

  return (
    // Do not remove wrapping div, it prevents ScrollMagic breaking on unmount
    <div>
      <PinnedSection>
        <Wrapper>
          <ContentWrapper
            style={{
              transform: translation,
            }}
          >
            <Container>
              <div className="col-12 col-sm-8 offset-sm-2 col-md-5 offset-md-1 col-lg-6">
                <Content>
                  <li>
                    {title && (
                      <Title
                        dangerouslySetInnerHTML={{
                          __html: title,
                        }}
                      />
                    )}
                    {subheading && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: subheading,
                        }}
                      />
                    )}
                  </li>
                  {blocks.map((block, index) => {
                    if (!block.title && !block.text) return null;
                    return (
                      <li key={`block-${index}`}>
                        {block?.title && (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: block.title,
                            }}
                          />
                        )}
                        {block?.text && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: block.text,
                            }}
                          />
                        )}
                      </li>
                    );
                  })}
                </Content>
              </div>
            </Container>
          </ContentWrapper>

          <Canvas
            style={{
              backgroundImage: posterImage ? `url("${posterImage}")` : "none",
              backgroundSize: "contain",
            }}
            width="1080px"
            height="1080px"
            ref={canvasRef}
          />
        </Wrapper>
      </PinnedSection>
    </div>
  );
};

export default ScrollAnimation;

import { useSelector } from "react-redux";
import styled from "styled-components";
import { ActionType } from "../../../constants/product";
import useProductButtonTooltipPromotion from "../../../hooks/contentful/useProductButtonTooltipPromotion";
import useShoppingIntention from "../../../hooks/useShoppingIntention";
import useVariation from "../../../hooks/useVariation";
import appSelectors from "../../../store/app/selectors";
import { bestEligiblePromotion } from "../../../store/promotion/selectors";
import userSelectors from "../../../store/user/selectors";
import { responsive } from "../../../utils/style";
import Tooltip from "../Tooltip";

const CustomTooltip = styled(Tooltip)`
  ${responsive.lg`
    &:not(.full-width) {
      max-width: 370px;
    }
  `}
`;

export default function CtaDetailText({ product, customClass = '' }) {
  const isInStock = product.stockStatus === "in stock";
  const { tooltip } = useVariation("homepage-hello-bar", {});
  const isUserDataLoaded = useSelector(appSelectors.isUserDataLoaded);
  const authenticated = useSelector(userSelectors.isLoggedIn);
  const promotion = useSelector(bestEligiblePromotion);
  const shoppingIntention = useShoppingIntention();

  const productButtonTooltipPromotion = useProductButtonTooltipPromotion(
    promotion?.slug,
  );

  const validTooltip = tooltip?.loggedIn && tooltip?.loggedOut;
  const validContent = validTooltip || productButtonTooltipPromotion?.content;
  if (!isInStock || !isUserDataLoaded || !validContent) return null;

  const authedActionTypes = [
    ActionType.AuthedAdd,
    ActionType.QuickAdd,
    ActionType.Switch,
  ];
  const authedAdd =
    authenticated || authedActionTypes.includes(shoppingIntention?.actionType);

  return (
    <CustomTooltip className={customClass}>
      {productButtonTooltipPromotion?.content ||
        (authedAdd ? tooltip.loggedIn : tooltip.loggedOut)}
    </CustomTooltip>
  );
}

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cartService from "../../services/cart";
import { addProductToCart } from "../../store/cart/actions";
import ProductOffer from "../../store/product-offer/model";
import metrics from "../../utils/metrics";
import QuickViewComponent from "../plp/QuickView";
import UpsellTile from "./UpsellTile";
import styled from "styled-components";
import { Color } from "../../utils/style";

const LabelText = styled.span`
  text-transform: uppercase;
  padding: var(--spacing-0_25) var(--spacing-1);
  background: ${Color.ritualBlue};
  color: #fff;
  width: fit-content;
`;

export type ProductOfferUpsellTileProps = {
  labelText?: string;
  productOffer: ProductOffer;
};

const UpsellTileWrapper = styled.span`
  border: ${(p) => (p.hasLabel ? "1px" : "0")};
  border-color: ${Color.ritualBlue};
  border-style: solid;
  display: block;
`;

const ProductOfferUpsellTile = (props: ProductOfferUpsellTileProps) => {
  const [isBundleModelOpen, setIsBundleModelOpen] = useState(false);
  const dispatch = useDispatch();
  const productOffer = props.productOffer;

  const productBundle = useSelector((state) =>
    productOffer.contentfulProductBundle(state),
  );
  const price = useSelector((state) => productOffer.initialAmount(state));
  const strikeThroughPrice = useSelector((state) =>
    productOffer.futureAmount(state),
  );

  const onCtaClick = () => {
    metrics.track("CTA Clicked", {
      location: "Product Offer Upsell Tile",
      title: productOffer.name,
    });

    dispatch(
      addProductToCart(
        {
          productOfferId: productOffer.id,
        },
        {
          location: "Product Offer Upsell Tile",
        },
      ),
    );
    cartService.openCart();
  };

  if (!productBundle) {
    return null;
  }

  const hasLabel = props.labelText;

  return (
    <>
      {hasLabel && (
        <LabelText className={"typography-eyebrow2"}>
          {props.labelText}
        </LabelText>
      )}
      <UpsellTileWrapper hasLabel={hasLabel}>
        <UpsellTile
          title={productOffer.name}
          subheading={productBundle.shopDescription}
          price={price}
          strikeThroughPrice={strikeThroughPrice}
          image={productBundle.shopImages[0].gatsbyImageData}
          onCtaClick={onCtaClick}
          onMoreInfoClicked={() => {
            setIsBundleModelOpen(true);
          }}
          savingsText={productBundle.flag}
        />
      </UpsellTileWrapper>
      <QuickViewComponent
        item={{
          content: productBundle,
          price: String(price / 100),
          futurePrice: String(strikeThroughPrice / 100),
          savings: Math.floor((strikeThroughPrice - price) / 100).toFixed(0),
        }}
        open={isBundleModelOpen}
        closeQuickView={() => setIsBundleModelOpen(false)}
        location="Product Offer Upsell Tile Flyout"
        showCTA={true}
        showOverlay={true}
      />
    </>
  );
};

export default ProductOfferUpsellTile;

import cartService from "../../services/cart";
import metrics from "../../utils/metrics";
import ProductButton from "./ProductButton";
import CtaDetailText from "./hero/CtaDetailText";
import OutOfStockButton from "./OutOfStockButton";

type Props = {
  oos: boolean;
  location: string;
  animated?: boolean;
  isVisible?: boolean;
  price: number;
  futurePrice?: number;
  discountPrice?: number;
  className?: string;
  selectedPlan?: any;
  product?: any;
  productButtonProps?: any;
};

const AddProductButton = ({
  location,
  oos,
  animated,
  price,
  className,
  discountPrice,
  selectedPlan,
  product,
  productButtonProps,
}: Props) => {
  const handleTrackAddProduct = (e: any) => {
    metrics.track("CTA Clicked", {
      location,
      title: "Add to Cart",
    });

    cartService.openCart(e);
  };

  const simpleButtonProps = {
    actionType: "add-to-cart",
    noNavigate: false,
    product: product,
    trackCTAClick: handleTrackAddProduct,
    location: "Single Product PDP Hero",
    planType: selectedPlan?.billingType || "recurring",
    showPricing: false,
    className,
    ...productButtonProps,
    productButtonProps: {
      showAnimated: animated,
      discountPrice,
      animatedPrice: price !== discountPrice ? price : undefined,
    },
    selectedPlan,
  };

  const animatedButtonProps = {
    ...simpleButtonProps,
    discountPrice: price,
    showPricing: true,
    price: price !== discountPrice ? price : undefined,
  };

  if (oos) {
    return (
      <OutOfStockButton
        {...simpleButtonProps}
        plan={selectedPlan}
      />
    );
  }

  if (animated) {
    return <ProductButton {...animatedButtonProps} />;
  }
  return (
    <>
      <ProductButton {...simpleButtonProps} />
      <CtaDetailText product={product} customClass={"full-width"} />
    </>
  );
};

export default AddProductButton;

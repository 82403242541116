import styled from "styled-components";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { default as intl } from "../../../services/intl";
import metrics from "../../../utils/metrics";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { responsive } from "../../../utils/style";
import Hero from "../../bundle/Hero";
import Footnotes from "../../Footnotes";
import Reviews from "../Reviews";
import usePromotionDiscount from "../../../hooks/usePromotionDiscount";
import { getPromotionDetails } from "../../../utils/promotion";
import { bestEligiblePromotion } from "../../../store/promotion/selectors";
import productSelectors from "../../../store/product/selectors";
import { getCadencePlans } from "../../../utils/ritualData";
import MostHelpfulReviews from "../../reviews/MostHelpfulReviews";
import AggregatedStarRating from "../../reviews/AggregatedStarRating";
import { AccordionLabel, RButton } from "@ritual/essentials-for-react";
import GridColumn from "../../grid/GridColumn";
import GridContainer from "../../grid/GridContainer";
import GridRow from "../../grid/GridRow";
import AddProductButton from "../AddProductButton";
import ProductHeroCarousel from "../../../components/product/ProductHeroCarousel";
import { default as Benefits } from "../../../components/subscription/Benefits";
import FlyoutPanel from "../FlyoutPanel";
import CadenceSelector from "../CadenceSelector";
import { MagicSVGImage } from "../../../components/MagicSVGImage";
import { IconPlus, IconMinus } from "../../../utils/svg";
import Timeline from "../../product/Timeline";
import FAQsComponent from "../../bundle/FAQs";
import BundleFeaturedArticles from "../../bundle/BundleFeaturedArticles";
import ScrollMagicController from "../../ScrollMagicController";
import ValuePropsTicker from "../../plp/ValuePropsTicker";
import ScentSelector from "../ScentSelector";
import ScrollAnimation from "../ScrollAnimation";
import IngredientsOverviewSection from "../IngredientsOverviewSection";

import {
  SupplementFactsType,
  ValuePropType,
  HowToUse,
  ProductStudy,
} from "../../../utils/productTypes";
import ScientificTeam from "../ScientificTeam";
import { getPlanForId } from "../../../utils/planToProduct";

const Background = styled.div`
  border-bottom: 1px solid transparent;

  overflow: hidden;
  position: relative;
`;

const Spacer = styled.div`
  margin-top: 58px;
  padding-top: 0;

  ${responsive.md`
    margin-top: 70px;
  `}

  @media (min-width: 750px /* Grid S Tablet */) {
    padding-top: var(--spacing-3);
  }

  &.desktop {
    display: none;

    @media (min-width: 750px /* Grid S Tablet */) {
      display: block;
    }
  }

  &.mobile {
    display: block;

    @media (min-width: 750px /* Grid S Tablet */) {
      display: none;
    }
  }
`;

const TopRadialGradient = styled.span`
  display: none;

  ${responsive.sm`
    display: block;
  `};

  width: 603px;
  height: 603px;
  border-radius: 50%;
  z-index: -1;

  position: absolute;
  top: 304px;
  right: -315px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 230, 102, 0.6) 17.67%,
    rgba(255, 230, 102, 0) 100%
  );

  ${responsive.md`
    width: 733px;
    height: 733px;

    top: 402px;
    right: -386px;
  `};
`;

const BottomRadialGradient = styled(TopRadialGradient)`
  z-index: 1;
  display: block;
  width: 500px;
  height: 500px;
  top: 0;
  right: calc(500px * -0.5);

  ${responsive.md`
    display: block;
    width: 800px;
    height: 800px;
    top: calc(800px * -0.5);
    right: calc(800px * -0.5);
  `};
`;

const GradientFAQ = styled(BottomRadialGradient)`
  z-index: 1;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 214, 0, 0.8) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  top: 0;
  left: calc(500px * -0.5);

  ${responsive.sm`
    top: 0;
    left: calc(500px * -0.5);
  `}

  ${responsive.md`
    top: calc(500px * -0.2);
    left: calc(500px * -0.8);
  `}

   ${responsive.lg`
    top: calc(500px * -0.25);
    left: calc(500px* -0.8);
  `}
`;

const RightRadialGradient = styled(TopRadialGradient)`
  z-index: -1;
  display: block;

  width: 500px;
  height: 500px;
  top: calc(500px * -0.5);
  right: calc(500px * -0.5);

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 214, 0, 0.8) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  ${responsive.md`
    display: block;
    width: 800px;
    height: 800px;

    top: calc(800px * -0.5) - 40px;
    right: calc(800px * -0.5) - 40px;
  `};
`;

const FlyoutMenus = styled(AccordionLabel)`
  outline: none;
  &:first-of-type {
    border-top: 1px solid var(--indigo-blue);
  }

  &:hover {
    cursor: pointer;
    .r-button.primary button {
      background-color: var(--yellow-ochre-60);
    }
  }
`;

const FlyoutMenuWrapper = styled.div`
  margin: var(--spacing-3) -24px;

  accordion-label {
    border-color: var(--solid-neutral-cool-40, #eaeef0);
    padding: var(--spacing-2) var(--spacing-1_5);

    &:first-of-type {
      border-top: 1px solid var(--solid-neutral-cool-40, #eaeef0) !important;
    }

    ${responsive.sm`
      padding: var(--spacing-1_5, 24px) 0;
    `}
  }

  ${responsive.sm`
    margin: var(--spacing-3) 0;
    padding: unset;
  `}
`;

const HeroWrapper = styled.div`
  margin: unset;

  ${responsive.sm`
    margin-left: var(--spacing-1);
    margin-right: var(--spacing-2);
  `}

  ${responsive.md`
    margin-left: var(--spacing-0_5);
    margin-right: var(--spacing-3);
  `}

  ${responsive.lg`
    margin-right: var(--spacing-6);
  `}
`;

const ImageArea = styled.div.attrs({
  id: "single-product-hero_container_image",
})`
  order: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow: hidden;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  margin: 0 -48px 0 -24px;

  ${responsive.sm`
    order: 1;
    padding-left: 0;
    justify-content: start;
  `}

  ${responsive.md`
    justify-content: space-between;
  `}
`;

const CarouselWrapper = styled.div`
  position: relative;
  margin: 0 9px;

  ${responsive.sm`
    margin: 0;
  `}
`;

const ArticlesWrapper = styled.div`
  width: 100%;
`;

const SciencenticTeamWrapper = styled.div`
  position: relative;
`;

const FAQWrapper = styled.div`
  position: relative;
`;

const ValuePropsTickerSpacer = styled.div`
  margin-bottom: var(--spacing-5);

  @media (min-width: 933px) {
    margin-bottom: var(--spacing-6);
  }
`;

type ProductAttributes = {
  billing_type: string;
  category: string;
  currency: string;
  id: string;
  image_url: string;
  name: string;
  price: number;
  quantity: number;
  sku: string;
  slug: string;
  url: string;
  urlSlug: string;
  variant: string;
  variant_contents: string;
  variant_description: string;
};

type Product = {
  sku: string;
  productGroupId: string;
  name: { name: string };
};

type ContentfulImage = {
  title: string;
  description: string;
  gatsbyImageData: IGatsbyImageData;
};

type FAQMarkdown = {
  childMarkdownRemark: {
    rawMarkdownBody: string;
  };
};

type FAQ = {
  question: string;
  answer: string;
};

type FAQs = FAQ[];

type ContentfulProduct = {
  sku: string;
  name: { name: string };
  pageDescription: string;
  category: string;
  heroZoomImages: ContentfulImage[];
  heroFeaturedImages: ContentfulImage[];
  timelineHeading: string;
  timelineSubhead: string;
  timelinePhoto: IGatsbyImageData;
  timelineVideo: IGatsbyImageData;
  timelineData: {
    childMarkdownRemark: {
      rawMarkdownBody: string;
    };
  };
  supplementFacts: SupplementFactsType;
  valueProps: ValuePropType[];
  howToUse: HowToUse;
  faq: FAQMarkdown;
  productStudyModal: ProductStudy;
  ritualProduct: any;
  experts: any;
  slug: string;
  articles: any;
  designTour: any;
  ingredientsSubheader: any;
  ingredients: any;
  footnotes: any;
  showReviews: boolean;
  stockStatus: string;
};

type Props = {
  product: Product;
  data: {
    mostHelpful: any;
    mostCritical: any;
    allProductReview: any;
    contentfulProduct: ContentfulProduct;
  };
  selectedProduct: ContentfulProduct;
  productAttributes: ProductAttributes;
  showCarousel: boolean;
  reviewRatingsMap: any;
  reviewCountsMap: any;
  pageContext: any;
  handleSelectedProduct?: (product: Product) => {};
  selectedPlanId: string;
  setSelectedPlanId?: (planId: string) => {};
};

const SingleProductDetailPage = (props: Props) => {
  const {
    data,
    product,
    showCarousel = true,
    handleSelectedProduct,
    pageContext,
    selectedPlanId,
    setSelectedPlanId = () => {},
    selectedProduct,
    reviewRatingsMap,
    reviewCountsMap,
  } = props;
  const { productId } = pageContext;
  const productRating = {
    reviewScore: reviewRatingsMap[productId],
    reviewCount: reviewCountsMap[productId],
  };
  const productUrl = `${process.env.GATSBY_URL}/products/${data.contentfulProduct.slug}`;
  const contentfulReviews = data.allProductReview.edges.map(
    (e: { node: any }) => e.node,
  );
  const simpleName = `NameMap[${selectedProduct}]`;
  const { mostHelpful, mostCritical, contentfulProduct } = data;
  const {
    timelineHeading,
    timelineSubhead,
    timelinePhoto,
    timelineVideo,
    timelineData,
    name: { name: productName },
    pageDescription,
    category,
    heroZoomImages,
    heroFeaturedImages,
    sku,
    productStudyModal,
    ritualProduct,
    faq,
    experts,
    articles,
    designTour,
    ingredientsSubheader,
    ingredients,
    footnotes,
    showReviews,
  } = contentfulProduct;

  const cadencePlans = getCadencePlans(
    ritualProduct.ritualPlans,
    pageContext.currency,
  );
  const defaultPlan = cadencePlans.find(
    (plan) => plan.plan_id === selectedPlanId,
  );
  const [selectedPlan, setSelectedPlan] = useState(defaultPlan);
  const planForCta = getPlanForId(selectedPlanId);

  const price = (selectedPlan?.amount || 0) / 100;
  const bestPromotion = useSelector(bestEligiblePromotion);
  const promotion = usePromotionDiscount(bestPromotion, selectedPlan?.plan_id);
  const promotionDetails = getPromotionDetails(promotion, price);
  const promotionDiscountPrice = promotionDetails?.discountPrice;
  const currentPrice = promotionDiscountPrice || price;
  const savings = price - currentPrice;
  const showSavingsPrice = savings > 0;
  const showScientificTeam = experts && experts.length > 0;
  const showFeaturedArticles = articles && articles.length;
  const hasWindow = typeof window !== "undefined";

  const [scrollRef, setScrollRef] = useState(null);
  const [isMobile, setIsMobile] = useState(
    hasWindow && window.innerWidth < 750,
  );
  const handleResize = () => {
    const windowSize = window.innerWidth;
    setIsMobile(windowSize < 750);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const updatedPlan = cadencePlans.find(
      (plan) => plan.plan_id === selectedPlanId,
    );
    setSelectedPlan(updatedPlan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlanId]);

  const timeline = JSON.parse(timelineData.childMarkdownRemark.rawMarkdownBody);

  const faqs: FAQs = JSON.parse(faq.childMarkdownRemark.rawMarkdownBody);

  // Scent Selector
  const allProducts = useSelector(productSelectors.allProducts) as [];
  const epreProduct = allProducts.find(
    (p: Product) => p.sku === "EPRE60",
  ) as any;
  const productGroups = allProducts.filter(
    (p: Product) => p.productGroupId === epreProduct.productGroupId,
  );

  const showSelector = sku.includes("EPRE60") && productGroups.length;
  const [openFlyout, setOpenFlyout] = useState(false);
  const [flyoutIndex, showFlyoutAtIndex] = useState<string | undefined>();

  const handleClick = (index: string) => {
    setOpenFlyout(true);
    showFlyoutAtIndex(index);

    metrics.track("Flyout Viewed", {
      location: "Single Sku PDP",
      name: index,
    });

    return;
  };

  const onCloseFlyout = () => {
    setOpenFlyout(false);
    setTimeout(() => {
      showFlyoutAtIndex(undefined);
    }, 300);
  };

  const flyoutMenus = [
    intl.t("product.supplement-facts.heading", "Supplement Facts"),
    intl.t("product.protein.how-to-use.heading", "How to Use"),
    productStudyModal && productStudyModal.linkText,
  ];

  const isProtein = category.includes("Protein");

  const isOOS = contentfulProduct.stockStatus !== "in stock";

  const showPlanSelector = cadencePlans.length > 1 && !isOOS;

  return (
    <>
      <Background>
        <GridContainer>
          <Spacer className="mobile" />
          {isMobile && (
            <ImageArea>
              <CarouselWrapper>
                <ProductHeroCarousel
                  sku={sku}
                  trimmed={!showCarousel}
                  zoomEnabled={showCarousel}
                  images={heroFeaturedImages}
                  zoomImages={heroZoomImages}
                  options={{
                    bleedLeft: false,
                    bleedRight: false,
                  }}
                />
              </CarouselWrapper>
            </ImageArea>
          )}
          <GridRow>
            <GridColumn xs={{ size: 6 }} s={{ size: 6 }} m={{ size: 6 }}>
              <Spacer className="desktop" />
              <HeroWrapper>
                <Hero
                  title={productName}
                  description={category}
                  heroDescription={pageDescription}
                  price={currentPrice}
                  futurePrice={showSavingsPrice ? price : undefined}
                  hidePriceBreakdown={showPlanSelector || isOOS }
                  showProbioticBlend={sku === "SYN30"}
                  showPrenatalBadge={sku === "EPRE60" || sku === "EPRE60-M"}
                  showPromoSavings={!showPlanSelector && showSavingsPrice}
                >
                  {showSelector && (
                    <ScentSelector
                      key={"scent-selector"}
                      fullWidth={true}
                      simpleLabel={true}
                      onClick={handleSelectedProduct}
                      products={productGroups}
                      selectedProduct={product}
                    />
                  )}
                  {showPlanSelector && (
                    <CadenceSelector
                      key={"cadence-selector"}
                      cadencePlans={cadencePlans}
                      selectedPlanId={selectedPlanId}
                      setSelectedPlanId={setSelectedPlanId}
                    />
                  )}
                  <AddProductButton
                    key={"add-product-button"}
                    oos={false}
                    productButtonProps={{prefix: "Add to Cart"}}
                    location="Single Product PDP HERO"
                    price={price}
                    className="fullwidth"
                    product={contentfulProduct ? contentfulProduct : product}
                    selectedPlan={planForCta}
                  />
                </Hero>
                <FlyoutMenuWrapper>
                  {flyoutMenus.map((menuLabel, index) => {
                    if (!menuLabel) return null;
                    return (
                      <FlyoutMenus
                        key={index}
                        onClick={() => handleClick(menuLabel)}
                      >
                        <span slot="accordion-label">{menuLabel}</span>
                        <RButton
                          slot="accordion-icon-button"
                          buttonClass="round-icon primary typography-body3 font-circular"
                        >
                          <MagicSVGImage
                            src={
                              flyoutIndex && flyoutIndex === menuLabel
                                ? IconMinus
                                : IconPlus
                            }
                            alt={`${
                              flyoutIndex && flyoutIndex === menuLabel
                                ? "Minus"
                                : "Plus"
                            }`}
                            width={24}
                            height={24}
                          />
                        </RButton>
                      </FlyoutMenus>
                    );
                  })}
                </FlyoutMenuWrapper>

                <Benefits products={[product]} isBundle={false} />
              </HeroWrapper>
            </GridColumn>
            {!isMobile && (
              <GridColumn xs={{ size: 6 }} s={{ size: 6 }} m={{ size: 6 }}>
                <Spacer className="desktop" />
                <ImageArea>
                  <CarouselWrapper>
                    <ProductHeroCarousel
                      sku={sku}
                      trimmed={!showCarousel}
                      zoomEnabled={showCarousel}
                      images={heroFeaturedImages}
                      zoomImages={heroZoomImages}
                    />
                  </CarouselWrapper>
                </ImageArea>
              </GridColumn>
            )}
          </GridRow>

          <GridRow>
            <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
              <Timeline
                timelineHeading={timelineHeading}
                timelineSubhead={timelineSubhead}
                image={timelinePhoto}
                video={timelineVideo}
                data={timeline}
                controller={scrollRef}
                singleProduct={true}
              />

              <ScrollMagicController
                ref={(r) => {
                  r && setScrollRef(r.controller);
                }}
              />
            </GridColumn>
          </GridRow>
        </GridContainer>

        <GridRow>
          <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
            <ValuePropsTickerSpacer>
              <ValuePropsTicker />
            </ValuePropsTickerSpacer>
          </GridColumn>
        </GridRow>

        <GridRow>
          <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
            <ScrollAnimation
              key={"design-tour"}
              controller={scrollRef}
              data={designTour}
            />
          </GridColumn>
        </GridRow>

        {/* Ingredients */}
        <GridRow>
          <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
            <IngredientsOverviewSection
              product={contentfulProduct}
              productType={sku}
              ingredients={ingredients}
              ingredientsSubheader={ingredientsSubheader}
              showQualityOverQuantity={isProtein}
              showOtherIngredients={isProtein}
            />
          </GridColumn>
        </GridRow>

        <GridContainer>
          {/* FAQ */}
          <GridRow>
            <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
              <FAQWrapper>
                <FAQsComponent faqs={faqs} singleProduct={true} />
                <GradientFAQ />
              </FAQWrapper>
            </GridColumn>
          </GridRow>
        </GridContainer>

        {/* Articles */}
        {showFeaturedArticles && (
          <ArticlesWrapper>
            <BundleFeaturedArticles
              articles={articles}
            ></BundleFeaturedArticles>
          </ArticlesWrapper>
        )}

        <GridContainer>
          {/* Scientific Board */}
          {showScientificTeam && (
            <SciencenticTeamWrapper>
              <RightRadialGradient />
              <ScientificTeam experts={experts} />
            </SciencenticTeamWrapper>
          )}
        </GridContainer>
        {showReviews &&
          !!productRating?.reviewScore &&
          !!contentfulReviews?.length && (
            <Reviews
              reviews={contentfulReviews}
              productUrl={productUrl}
              productName={simpleName}
              productId={productId}
              seeAllReviewsCta={true}
              isProductPage={true}
              {...productRating}
            >
              <AggregatedStarRating
                score={productRating.reviewScore}
                totalReviews={productRating.reviewCount}
                productId={productId}
              />
              {mostHelpful && mostCritical && (
                <MostHelpfulReviews
                  mostHelpful={mostHelpful}
                  mostCritical={mostCritical}
                />
              )}
            </Reviews>
          )}

        <Footnotes footnotes={footnotes} />
        <FlyoutPanel
          openIndex={flyoutIndex}
          open={openFlyout}
          item={{
            products: [contentfulProduct],
          }}
          product={contentfulProduct}
          closeQuickView={onCloseFlyout}
          hideTabs={true}
        />
      </Background>
    </>
  );
};

export default SingleProductDetailPage;

import styled from "styled-components";

// Utils
import { Color, responsive, rem, Font } from "../../../utils/style";

// Hooks
import { getHowToUseData } from "../../../hooks/howToUseData";

// Services
import intl from "../../../services/intl";

// Components
import Text from "../../Text";
import Container from "../../Container";
import Row from "../../Row";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const HowToUseSection = styled(Container)`
  margin: 0 0 80px;

  h2 {
    margin: 40px 0 24px;
    font-size: ${rem(16)};
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 26px;
    padding: 0;

    ${responsive.sm`
      margin-top: 0px;
    `}

    ${responsive.md`
      margin-bottom: 32px;
    `}
  }

  ${responsive.sm`
    margin: 0 auto 120px;
  `}
`;

const HowToUseAssetContainer = styled.div.attrs({
  className: "col-sm-6",
})`
  ${responsive.sm`
    width: 100%;
    display: flex;
    justify-content: center;
  `}
`;

const HowToUseContent = styled.div.attrs({
  className: "col-sm-6",
})`
  order: 1;

  ${responsive.sm`
    display: flex;
    order: unset;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
  `}

  ${responsive.lg`
    max-width: 510px;
    margin-right: auto;
  `}
`;

const HowToList = styled.ol.attrs({
  className: "list-unstyled",
})`
  margin: 0;

  li {
    padding: 24px 0;
    color: ${Color.ritualBlue};
    font-size: ${rem(22)};
    letter-spacing: -0.3px;
    line-height: 36px;
    border-top: 2px solid rgba(20, 43, 111, 0.24);
    display: flex;

    ${responsive.md`
    font-size: ${rem(26)};
      padding: 32px 0;
    `}

    span:first-child {
      ${Font.dutch}
      font-style: italic;
      margin-right: 24px;
    }

    span:nth-child(2) {
      font-weight: 500;
    }

    &:last-child {
      border-bottom: 2px solid rgba(20, 43, 111, 0.24);
    }
  }
`;

const HowToUseInstructions = styled.p`
  ${Font.dutch}
  padding: 0;
  letter-spacing: 0;
  line-height: 26px;
  font-size: ${rem(16)};
  margin-top: 32px;
  margin-bottom: 0;

  ${responsive.md`
    margin-top: 40px;
    font-size: ${rem(18)};
  `}
`;

const Heading = styled.h2`
  font-size: ${rem(16)};
  text-transform: uppercase;
  margin-bottom: 16px;
  padding-left: 20px;

  ${responsive.sm`
    padding-left: 0;
    margin-top: 0;
  `}
`;

const HowToUse = ({ productType = "essential.fertility" }) => {
  const contentData = getHowToUseData();
  const productData = contentData[productType];

  if (!productData) return null;

  const { content, image, instructions, disclaimer } = productData;

  function padIndex(value) {
    return value.toLocaleString(intl.locale, {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  }

  return (
    <HowToUseSection>
      <Row>
        <HowToUseContent>
          <Heading>
            <Text
              id="product.protein.how-to-use.heading"
              defaultMessage="How to Use"
            />
          </Heading>
          <HowToList>
            {content.map(({ id, defaultMessage }, i) => (
              <li key={i}>
                <span>{padIndex(i + 1)} </span>
                <span>
                  <Text id={id} defaultMessage={defaultMessage} />
                </span>
              </li>
            ))}
          </HowToList>
          <HowToUseInstructions>
            <Text
              id={instructions.id}
              defaultMessage={instructions.defaultMessage}
            />
          </HowToUseInstructions>
          <HowToUseInstructions>
            <Text
              id={disclaimer.id}
              defaultMessage={disclaimer.defaultMessage}
            />
          </HowToUseInstructions>
        </HowToUseContent>
        <HowToUseAssetContainer>
          <GatsbyImage
            alt={image.description}
            image={getImage(image)}
            loading="eager"
            style={{
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
              width: "100%",
              backgroundColor: Color.ritualGrey,
            }}
          />
        </HowToUseAssetContainer>
      </Row>
    </HowToUseSection>
  );
};

export default HowToUse;

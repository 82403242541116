import styled from "styled-components";

import GridColumn from "../grid/GridColumn";
import GridRow from "../grid/GridRow";
import { PlanTile, PlanTileBenefit } from "@ritual/essentials-for-react";
import intl from "../../services/intl";
import { cadencePlanDetails } from "../../utils/ritualData";
import classNames from "classnames";
import { Icons } from "../../utils/react-svg";

const PlanSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacing-1);
`;

const CadenceSelectorHeading = styled.h3`
  margin: var(--spacing-0_5) 0;
`;

const CadenceButton = styled.button<{ selected: boolean }>`
  border: none;
  margin: var(--spacing-0_5) 0;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: "#f0f0f0";
  }
`;

const PlanTileContainer = styled(PlanTile)`
  &.one-time {
    .plan-tile__header .title {
      margin-bottom: unset;
    }
  }

  &.selected {
    .price-row .base-price {
      font-weight: 450 !important;
    }
  }

  .radio-button {
    height: unset;
    width: unset;
    position: relative;
    bottom: 2px;

    img {
      height: 18px;
      width: 18px;
    }
  }

  .plan-tile__header {
    align-items: baseline;
    
    .title {
      margin-bottom: unset;
  
      div.label {
        color: unset;
        margin-bottom: unset;
      }
    }
  }

  .plan-tile-benefit-row {
    text-align: left;
  }

  .benefit-icon {
    svg {
      width: 18px;
      height: 18px;

      g g {
        fill: var(--indigo-blue-60);
      }
    }
  }

  &.hide-benefits {
    .plan-tile__benefits {
      display: none;
    }
  }
`;


const RadioImage = (props: any) => {
  const { selected } = props;
  const selectedStatus = selected ? "selected" : "unselected";
  return (
    <img
      slot="plan-tile-radio-button"
      alt={`radio-${selectedStatus}`}
      src={`https://assets.ritual.com/icons/icon-radio-${selectedStatus}.svg`}
      aria-hidden="true"
    />
  );
};

type Props = {
  cadencePlans: any[];
  selectedPlanId: string;
  setSelectedPlanId: (planId: string) => void;
};

const CadenceSelector = (props: Props) => {
  const { cadencePlans, selectedPlanId, setSelectedPlanId } = props;

  if (!cadencePlans) return null;

  const defaultPlanId =
    selectedPlanId ||
    cadencePlans.find(
      (plan: any) =>
        plan.interval_count === 30 && plan.billing_type === "recurring",
    ).plan_id;

  return (
    <PlanSelectorContainer className="test-id">
      <GridRow>
        <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
          <CadenceSelectorHeading className="typography-body2 font-circular">
            {intl.t("delivery-cadence.selector.header")}
          </CadenceSelectorHeading>
          {cadencePlans.map((plan, index) => {
            const {
              selectorHeader,
              amount,
              basePrice,
              monthlyAmount,
              monthlyPercentSavings,
              cadenceClarity,
            } = cadencePlanDetails(plan);

            const isSelected = selectedPlanId
              ? plan.plan_id === selectedPlanId
              : plan.plan_id === defaultPlanId;
            const isOneTimePlan = plan.billing_type === "one_time";

            return (
              <CadenceButton
                key={index}
                selected={isSelected}
                onClick={() => setSelectedPlanId(plan.plan_id)}
                aria-label="cadence-selector-option"
              >
                <PlanTileContainer
                  className={!isSelected ? "hide-benefits" : ""}
                  isSelected={isSelected}
                >
                  <RadioImage selected={isSelected} />
                  <span
                    className={classNames("font-circular", {
                      "typography-label1": isSelected,
                      "typography-body1": !isSelected,
                    })}
                    slot="plan-tile-title"
                  >
                    {selectorHeader}
                  </span>

                  {amount && <span slot="plan-tile-final-price">{amount}</span>}

                  {!isOneTimePlan && (
                    <>
                      {basePrice && (
                        <span slot="plan-tile-base-price">{basePrice}</span>
                      )}
                      {monthlyAmount && (
                        <span slot="plan-tile-monthly-price">{`${monthlyAmount}/month`}</span>
                      )}
                      {monthlyPercentSavings && (
                        <span slot="plan-tile-savings">{`Subscribe and Save ${monthlyPercentSavings}`}</span>
                      )}
                      <PlanTileBenefit
                        slot="plan-tile-benefit"
                      >
                        <span className="benefit-icon" slot="plan-tile-benefit-icon">
                          <Icons.Recurring />
                        </span>
                        <span slot="plan-tile-benefit-text">
                          {cadenceClarity}
                        </span>
                      </PlanTileBenefit>
                      <PlanTileBenefit
                        slot="plan-tile-benefit"
                      >
                        <span className="benefit-icon" slot="plan-tile-benefit-icon">
                          <Icons.CircleStar />
                        </span>
                        <span slot="plan-tile-benefit-text">
                          {intl.t(
                            `delivery-cadence.benefits.${
                              plan.number_of_months === 1
                                ? "monthly"
                                : "non-monthly"
                            }`,
                          )}
                        </span>
                      </PlanTileBenefit>
                    </>
                  )}
                </PlanTileContainer>
              </CadenceButton>
            );
          })}
        </GridColumn>
      </GridRow>
    </PlanSelectorContainer>
  );
};

export default CadenceSelector;

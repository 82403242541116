import styled from "styled-components";
import GridColumn from "./grid/GridColumn";
// TODO: Update to GridContainer when we move PDP to new grid
// import GridContainer from "./grid/GridContainer";
import GridRow from "./grid/GridRow";

const FootnotesContent = styled.div`
  padding: var(--spacing-3) 0;

  @media (min-width: 933px) {
    padding: var(--spacing-4) 0;
  }

  .footnotes-color {
    color: var(--indigo-blue-40);
    padding: 0;
    margin: 0;
  }
`;

type PropTypes = {
  footnotes: {
    childMarkdownRemark: {
      html: string;
    };
  };
};

const Footnotes = ({ footnotes }: PropTypes) => {
  if (!footnotes) return null;

  const html = footnotes?.childMarkdownRemark?.html || "";
  const footnotesHtml = html.replace(
    /<p>/g,
    '<p class="typography-caption footnotes-color">',
  );

  return (
    <div className="container">
      <GridRow>
        <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
          <FootnotesContent
            dangerouslySetInnerHTML={{
              __html: footnotesHtml,
            }}
          />
        </GridColumn>
      </GridRow>
    </div>
  );
};

export default Footnotes;

import { useState } from "react";
import styled, { css } from "styled-components";

import { ActionType } from "../../constants/product";

// Redux
import { useSelector } from "react-redux";
import productSelectors from "../../store/product/selectors";

// Components
import ProductSelector from "../bundle/ProductSelector";
import Container from "../Container";
import Ribbon from "../global/Ribbon";
import Row from "../Row";
import Text from "../Text";
import FormulaHighlights from "./FormulaHighlights";
import ActionEyebrow from "./hero/ActionEyebrow";
import ProductName from "./hero/ProductName";
import ProductOfferUpsell from "./hero/ProductOfferUpsell";
import ShakerPromo from "./hero/ShakerPromo";
import SubscriptionBenefits from "./hero/SubscriptionBenefits";
import ObgynClaim from "./ObgynClaim";
import ProbioticBlend from "./ProbioticBlend";
import ProductCta from "./ProductCta";
import ProductHeroCarousel from "./ProductHeroCarousel";
import ScentSelector from "./ScentSelector";
import StarRating from "./StarRating";
import SupplementFacts from "./SupplementFacts";
import ProductStudyModal from "./templates/ProductStudyModal";

// Utils
import metrics from "../../utils/metrics";
import { getPromotionDetails } from "../../utils/promotion";
import { Color, media, Opacity, rem, responsive } from "../../utils/style";

// Hooks
import useProductSpecificPromotion from "../../hooks/discount/useProductSpecificPromotion";
import useShoppingIntention from "../../hooks/useShoppingIntention";
import useVariation from "../../hooks/useVariation";

const HeroArea = styled.section.attrs({ id: "product-hero" })`
  background-color: ${(p) => p.backgroundColor || "#EEECF5"};
  width: 100%;
  cursor: initial;
`;

const StyledContainer = styled(Container).attrs({
  id: "product-hero_container",
  className: "container",
})`
  height: 100%;
  overflow: visible !important;
  margin-top: 52px;

  ${responsive.sm`
    margin-bottom: 24px;
  `}

  ${responsive.md`
    margin-top: 68px;
    margin-bottom: 24px;
  `}
`;

const StyledRow = styled(Row).attrs({
  id: "product-hero_container_row",
  className: "row",
})`
  height: 100%;

  ${responsive.sm`
    padding-top: 24px;
  `}

  ${responsive.md`
    padding-top: 40px;

    hr {
      width: 100%;
      margin: 0;
    }
  `}
`;

const ContentArea = styled.div.attrs({
  id: "product-hero_container_row_content",
  className: "col-12 col-sm-5",
})`
  display: flex;
  flex-direction: column;

  order: 0;
  padding-left: 0;

  ${media.mobile`
    order: 1;
    padding: 24px 24px 16px;
  `}

  ${responsive.md`
    padding-right: 0;
  `};

  ${responsive.lg`
    justify-content: center;
    padding-right: 15px;
  `}
`;

const Divider = styled.hr`
  background: ${Color.fadedGrey};
  height: 1px;
  border: none;
  margin: 32px 0 0;
`;

const ImageArea = styled.div.attrs({
  id: "product-hero_container_row_image",
  className: "col-12 col-sm-6 offset-sm-1",
})`
  order: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${responsive.sm`
    order: 1;
    padding-left: 0;
    justify-content: start;
  `}

  ${responsive.md`
    justify-content: space-between;
  `}
`;
const StyledProductHeaderDescription = styled.div`
  margin-top: 16px;

  ${responsive.md`
    margin-top: 24px;
  `}
  p {
    margin: 0;
    font-weight: 300;
    letter-spacing: 0px;
    font-size: ${rem(14)};
    line-height: ${rem(24)};

    ${responsive.md`
      font-size: ${rem(16)};
      line-height: ${rem(26)};
    `}
  }
`;

const LinkContainer = styled.div`
  margin: var(--spacing-0_5, 8px) 0 var(--spacing-1, 16px);

  ${responsive.sm`
    text-wrap: nowrap;
  `}

  ${responsive.md`
    margin: var(--spacing-1, 16px) 0;
  `}
`;

const LinkButton = styled.button`
  margin-right: 24px;

  ${responsive.md`
    margin-right: 32px;
  `}

  &:last-of-type {
    margin-right: 0;
  }

  appearance: none;
  border: none;
  background: none;

  display: inline-block;
  padding: 0;

  font-weight: 500;
  letter-spacing: 0px;
  font-size: ${rem(14)};
  line-height: ${rem(24)};

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  `}

  color: ${Color.ritualBlue};
  border-bottom: 2px solid ${Color.ritualBlue};
  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &:hover {
    opacity: ${Opacity.light};
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: var(--spacing-1_5, 24px);

  ${responsive.sm`
    justify-content: flex-start;
  `}
`;

const CarouselWrapper = styled.div`
  position: relative;
  margin: 0 9px;

  ${responsive.sm`
    margin: 0;
  `}
`;

const ribbonContentStyle = css`
  margin-bottom: 16px;
`;

const useModalState = (openEvent) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = (e) => {
    metrics.track(...openEvent(e));
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return { isOpen, open, close };
};

const useSupplementFactsModalState = () =>
  useModalState(() => [
    "Overlay Opened",
    { location: "PDP Hero", title: "Supplement Facts" },
  ]);

const useProductStudyModalState = () =>
  useModalState((e) => [
    "Overlay Opened",
    { location: "PDP Hero", title: e.target.innerText },
  ]);

const ProductHeaderDescription = ({ headerDescription }) => {
  const htmlHeaderDescription = `<p>${headerDescription}</p>`;
  const accessibleFooterLinkHtml =
    '<a aria-describedby="disclaimer-footnote-label" href="#disclaimer-footnote" id="footnote-ref">*</a>';
  const htmlHeaderDescriptionWithLink = htmlHeaderDescription.replace(
    "*",
    accessibleFooterLinkHtml,
  );

  return (
    <StyledProductHeaderDescription
      dangerouslySetInnerHTML={{ __html: htmlHeaderDescriptionWithLink }}
    />
  );
};

const ProductReview = ({ reviewScore, reviewCount }) => {
  const showReviews = useVariation("pdp-hero-reviews-star-rating");

  if (!showReviews || !reviewScore) return null;

  return (
    <StarRating
      readReviews={true}
      score={reviewScore}
      count={reviewCount}
      productHero={true}
    />
  );
};

const ProductScentSelector = ({
  product,
  handleSelectedProduct,
  selectedProduct,
}) => {
  const allProducts = useSelector(productSelectors.allProducts);
  const productForContentfulProduct = allProducts.find(
    (p) => p.sku === product.sku,
  );
  const allProductsFromGroup = allProducts.filter(
    (p) => p.productGroupId === productForContentfulProduct.productGroupId,
  );

  return (
    <ScentSelector
      onClick={handleSelectedProduct}
      products={allProductsFromGroup}
      selectedProduct={selectedProduct}
    />
  );
};

const ProductHero = (props) => {
  const {
    product,
    heroBackgroundColor,
    featuredImages,
    zoomImages,
    showCarousel = true,
    reviewScore,
    reviewCount,
    showFormulaHighlights,
    productData,
    showActionEyebrow = true,
    showProbioticBlend,
    showObgynClaim = false,
    selectedProduct,
    productStudyModal = null,
    headerDescription,
    showProductSelector,
    showCta = true,
    selectorProducts,
    selectorLabels,
    setSelectedProduct,
    handleSelectedProduct,
    // eslint-disable-next-line no-unused-vars
    productButtonProps = {},
  } = props;
  const { scentSelector } = product;

  const shoppingIntention = useShoppingIntention();
  const actionType = props.actionType || shoppingIntention.actionType;
  const ribbonPromotion = useProductSpecificPromotion(
    actionType,
    props.product,
  );

  const supplementFactsModalState = useSupplementFactsModalState();
  const productStudyModalState = useProductStudyModalState();

  const promoProduct = useSelector((state) =>
    productSelectors.productForSku(state, { sku: "PROSHAKER" }),
  );

  return (
    <HeroArea
      backgroundColor={heroBackgroundColor}
      aria-labelledby="product-name"
    >
      <StyledContainer>
        <StyledRow>
          <ContentArea>
            {!!ribbonPromotion && actionType === ActionType.AddToCart && (
              <Ribbon contentStyle={ribbonContentStyle}>
                <Text
                  id="product.hero.product-specific-promotion"
                  defaultMessage="Save {amount}"
                  values={{
                    amount: getPromotionDetails(ribbonPromotion).formattedValue,
                  }}
                />
              </Ribbon>
            )}

            {showActionEyebrow && (
              <ActionEyebrow product={product} actionType={actionType} />
            )}

            <ProductName product={product} />

            <ProductReview
              reviewScore={reviewScore}
              reviewCount={reviewCount}
            />

            <ProductHeaderDescription headerDescription={headerDescription} />

            {showProbioticBlend && <ProbioticBlend />}

            {showObgynClaim && <ObgynClaim />}

            {showFormulaHighlights && product.formulaHighlights && (
              <FormulaHighlights product={product} />
            )}

            <LinkContainer>
              <LinkButton onClick={supplementFactsModalState.open}>
                <Text
                  id="product.hero.supplement-facts"
                  defaultMessage="Supplement Facts"
                />
              </LinkButton>

              {productStudyModal?.linkText && (
                <LinkButton
                  onClick={productStudyModalState.open}
                  data-modal={`${productData.name} product study modal`}
                >
                  {productStudyModal.linkText}
                </LinkButton>
              )}
            </LinkContainer>

            {showCta && showProductSelector && !scentSelector && (
              <ProductSelector
                products={selectorProducts}
                labels={selectorLabels}
                selectedProduct={product}
                setSelectedProduct={setSelectedProduct}
                setSelectedPlan={() => {}}
              />
            )}

            {showCta && scentSelector && (
              <ProductScentSelector
                product={product}
                handleSelectedProduct={handleSelectedProduct}
                selectedProduct={selectedProduct}
              />
            )}

            {showCta && (
              <ButtonWrapper>
                <ProductCta
                  {...props}
                  product={selectedProduct ? selectedProduct : product}
                />
              </ButtonWrapper>
            )}

            <ShakerPromo
              className="d-block d-sm-none d-md-block mt-3"
              promoProduct={promoProduct}
              productData={productData}
            />

            <ProductOfferUpsell productSku={product.sku} />

            <div className={"w-100 d-block"}>
              <SubscriptionBenefits product={product} />
              <Divider className="d-block d-sm-none" />
            </div>
          </ContentArea>

          <ImageArea>
            <CarouselWrapper>
              <ProductHeroCarousel
                sku={selectedProduct ? selectedProduct.sku : product.sku}
                trimmed={!showCarousel}
                zoomEnabled={showCarousel}
                images={featuredImages}
                zoomImages={zoomImages}
              />
            </CarouselWrapper>
            <ShakerPromo
              className="d-none d-sm-flex d-md-none mt-sm-6 mt-md-0"
              promoProduct={promoProduct}
              productData={productData}
            />
          </ImageArea>
        </StyledRow>
      </StyledContainer>

      <SupplementFacts
        supplementFacts={product.supplementFacts}
        isOpen={supplementFactsModalState.isOpen}
        onRequestClose={supplementFactsModalState.close}
      />

      {productStudyModalState.isOpen && (
        <ProductStudyModal
          productData={productData}
          productStudyModal={productStudyModal}
          isOpen={productStudyModalState.isOpen}
          onRequestClose={productStudyModalState.close}
        />
      )}
    </HeroArea>
  );
};

export default ProductHero;

import { Component } from "react";
import Helmet from "react-helmet";

// Utils
import { getProductForContentfulId } from "../../utils/planToProduct";
import {
  getAgeGroupFromProduct,
  getGenderFromProduct,
  getSeoCategoryFromProduct,
} from "../../utils/product";
import config from "../../utils/siteConfig";

// Components
import GeneralTags from "./GeneralTags";

class ProductSEO extends Component {
  static defaultProps = {
    productAttributes: {
      brand: "Ritual",
      availability: "in stock",
      condition: "new",
      category: getSeoCategoryFromProduct(),
      currency: "USD",
    },
  };

  getSchemaStockStatus(stockStatus) {
    switch (stockStatus) {
      case "preorder":
        return "http://schema.org/PreOrder";
      case "out of stock":
        return "http://schema.org/OutOfStock";
      case "in stock":
      default:
        return "http://schema.org/InStock";
    }
  }

  getCountryCodeFromCurrency(currency) {
    switch (currency) {
      case "CAD":
        return "CA";
      case "GBP":
        return "GB";
      case "USD":
      default:
        return "US";
    }
  }

  render() {
    const {
      pagePath,
      title,
      description,
      image,
      image: { url: imgUrlPartial, width: imgWidth, height: imgHeight },
      productAttributes,
    } = this.props;

    const pageUrl = `${config.siteUrl}/${pagePath}`;
    const imgUrl = `https:${imgUrlPartial}`;

    const { amount, discountAmount, priceValidUntil } = productAttributes;
    const hasDiscount = !!discountAmount;

    if (!productAttributes) {
      console.warn("Missing productAttributes from page SEO");
    }

    const product = getProductForContentfulId(productAttributes.contentfulId);

    let productMeta = [
      { property: "product:brand", content: productAttributes.brand?.name },
      {
        property: "product:availability",
        content: productAttributes.availability,
      },
      { property: "product:condition", content: productAttributes.condition },
      {
        property: "product:retailer_item_id",
        content: productAttributes.productId,
      },
      { property: "og:price:amount", content: amount },
      { property: "og:price:currency", content: productAttributes.currency },
      { property: "product:price:amount", content: amount },
      {
        property: "product:price:currency",
        content: productAttributes.currency,
      },
      { property: "product:category", content: productAttributes.category },
    ];

    const gender = getGenderFromProduct(product);
    const ageGroup = getAgeGroupFromProduct(product);

    if (gender) {
      productMeta.push({ property: "product:gender", content: gender });
    }

    if (ageGroup) {
      productMeta.push({ property: "product:age_group", content: ageGroup });
    }

    // Overwrite properties below with new image attributes
    let meta = [
      { name: "image", content: imgUrl },
      { property: "og:type", content: "product.item" },
      { property: "og:image", content: imgUrl },
      { property: "og:image:width", content: imgWidth },
      { property: "og:image:height", content: imgHeight },
      { name: "twitter:image", content: imgUrl },
      ...productMeta,
    ];

    const availability = this.getSchemaStockStatus(
      productAttributes.availability,
    );

    const countryCode = this.getCountryCodeFromCurrency(
      productAttributes.currency,
    );

    const countryTransitTime = {
      CA: 10,
      US: 4,
      GB: 7,
    };

    let productSchema = {
      "@context": "http://schema.org",
      "@graph": [
        {
          "@type": "Product",
          name: productAttributes.name,
          description: productAttributes.description,
          brand: productAttributes.brand,
          image: {
            "@type": "ImageObject",
            url: imgUrl,
            width: imgWidth,
            height: imgHeight,
          },
          sku: productAttributes.productId,
          mpn: productAttributes.productId,
          gtin: productAttributes.gtin ?? undefined,
          offers: {
            "@type": "Offer",
            availability,
            url: pageUrl,
            priceSpecification: [
              {
                "@type": "UnitPriceSpecification",
                price: amount,
                priceCurrency: productAttributes.currency,
                priceType: "MSRP",
              },
              hasDiscount && {
                "@type": "UnitPriceSpecification",
                price: discountAmount,
                priceCurrency: productAttributes.currency,
                priceType: "SalePrice",
                validThrough: priceValidUntil
                  ? new Date(priceValidUntil).toISOString()
                  : undefined,
              },
            ],
            shippingDetails: {
              "@type": "OfferShippingDetails",
              shippingLabel: "Free Shipping",
              deliveryTime: {
                "@type": "ShippingDeliveryTime",
                businessDays: {
                  "@type": "OpeningHoursSpecification",
                  dayOfWeek: [
                    "https://schema.org/Monday",
                    "https://schema.org/Tuesday",
                    "https://schema.org/Wednesday",
                    "https://schema.org/Thursday",
                    "https://schema.org/Friday",
                  ],
                },
                handlingTime: {
                  "@type": "QuantitativeValue",
                  minValue: 0,
                  maxValue: 1,
                  unitCode: "d",
                },
                transitTime: {
                  "@type": "QuantitativeValue",
                  minValue: 1,
                  maxValue: countryTransitTime[countryCode] || 7,
                  unitCode: "d",
                },
              },
              shippingDestination: {
                "@type": "DefinedRegion",
                addressCountry: countryCode,
              },
              shippingRate: {
                "@type": "MonetaryAmount",
                currency: productAttributes.currency,
                value: 0,
              },
            },
            hasMerchantReturnPolicy: {
              "@type": "MerchantReturnPolicy",
              merchantReturnLink: config.returnPolicy,
              applicableCountry: "US",
              description: "30 Money-Back Guarantee without Product Return",
              merchantReturnDays: 30,
              returnFees: 0,
            },
          },
        },
      ],
    };

    if (productAttributes.reviewCount > 0) {
      productSchema["@graph"][0].aggregateRating = {
        "@type": "AggregateRating",
        ratingValue: productAttributes.ratingValue,
        reviewCount: productAttributes.reviewCount,
      };
      if (productAttributes.topReview) {
        productSchema["@graph"][0].review = {
          "@type": "Review",
          name: productAttributes.topReview.title,
          reviewBody: productAttributes.topReview.content,
          reviewRating: {
            "@type": "Rating",
            ratingValue: productAttributes.topReview.score,
          },
          author: {
            "@type": "Person",
            name: productAttributes.topReview.user.display_name,
          },
        };
      }
    }

    return (
      <div>
        <GeneralTags
          pagePath={pagePath}
          title={title}
          description={description}
          image={image}
          ogTitle={productAttributes.name}
        />
        <Helmet meta={meta}>
          <script type="application/ld+json">
            {JSON.stringify(productSchema)}
          </script>
        </Helmet>
      </div>
    );
  }
}

export default ProductSEO;
